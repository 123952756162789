export const p = {
  ru: `
  <h3>ПРАВИЛА ПОСЕЩЕНИЯ ВОДНОГО КОМПЛЕКСА «МОРЕ» </h3>

            <ul>
              <li>
              Режим работы Водного комплекса: Ежедневно, без выходных: с 10:00 до 22:00
              </li>
              <li>
              Оплата входного билета (или абонемента) подтверждает, что посетитель ознакомлен с настоящими Правилами, согласен с ними и обязуется их соблюдать, принимая на себя ответственность за нарушение этих правил.
              </li>
              <li>
              Вход в Водный комплекс запрещен лицам, не достигшим 18-летнего возраста, а также инвалидам без сопровождающего (воспитателя, учителя, родителя, законного представителя или другого сопровождающего). 
              </li>
              <li>
              Детям до 3-х лет вход бесплатный при предъявлении документа, подтверждающего возраст ребенка
              </li>
              <li>
              Услуги общественного питания и другие дополнительные услуги не включены в стоимость входного билета и оплачиваются посетителями дополнительно по собственному усмотрению.
              </li>
              <li>
              Чеки за входной билет и полотенце, а также за услуги общественного питания выдаются раздельно.
              </li>
              <li>
              Наличие входного билета не дает гарантии на наличие свободного шезлонга
              </li>
              <li>
              Шезлонги не бронируются
              </li>
              <li>
              В случае полного заполнения Водного Комплекса «МОРЕ» посетителями, Администрация вправе временно закрыть двери комплекса и предложить посетителю ожидать свободного шкафчика в порядке очереди, которая фиксируется с помощью выданных на ресепшн порядковых номерков.
              </li>
              <li>
              В случае технической неисправности или проведения ремонтных работ какого-либо оборудования или аттракционов, Исполнитель вправе запретить их эксплуатацию, проинформировав Посетителей путем размещения соответствующего уведомления на территории Водного комплекса или на официальном сайте в сети Интернет. При наличии такого уведомления стоимость входного билета (цена услуг) не подлежит уменьшению, а приобретенные билеты не подлежат возврату.
              </li>
              <li>
              Запрещено покидать территорию Водного комплекса в течение установленного времени. В случае, если Посетитель покинул территорию без сопровождающего сотрудника Администрации Водного комплекса и выразил желание вернуться, ему необходимо приобрести новый абонемент.
              </li>
              <li>
              Уплаченные денежные средства не возвращаются в случае отказа Посетителем от права воспользоваться услугами Водного комплекса по любым причинам после истечения 20 минут с момента оплаты абонемента.
              </li>
              <li>
              В случае нарушения перечисленных правил, Администрация «МОРЕ» вправе прекратить предоставление услуг, а также лишить Посетителя права дальнейшего использования услугами Водного Комплекса «МОРЕ» , в том числе – уже оплаченного входного билета, без компенсации его стоимости.
              </li>
              <li>
              Дети, не способные самостоятельно ходить в туалет, должны посещать бассейн и находиться в воде исключительно в специальных плавательных подгузниках.
              </li>
              <li>
              Дети, не умеющие самостоятельно плавать, должны находиться в бассейне в сопровождении одного из родителей/опекунов/сопровождающих.
              </li>
              <li>
              Гостям, не умеющим плавать, запрещено купаться без спасательных жилетов.
              </li>
              <li>
              Гостям Водного Комплекса "МОРЕ" необходимо соблюдать правила общественного порядка и поведения, создающие угрозу жизни и здоровью граждан, угрозу сохранности имущества Водного комплекса и третьих лиц.
              </li>
              <li>
              Не совершать иные действия, нарушающие общественный порядок и спокойствие других посетителей Водного комплекса.
              </li>
              <li>
              Запрещено посещение Водного Комплекса "МОРЕ" при наличии противопоказаний, кожных заболеваний таких как: лишай, пиодермии, демодекоз, ветрянка и прочих инфекционных заболеваний кожи, венерические заболевания, открытые язвы, менструация/кровотечения, диарея, Гепатит А, инфекционные заболевания передающихся воздушно-капельным путем, иных особенностей организма, препятствующих пользованию услугами Водного комплекса и создающих угрозу жизни и здоровья Посетителя(ей). 
              </li>
              <li>
              Необходимо сохранять электронный браслет, выданный Посетителю для прохода на территорию Водного комплекса до конца пребывания Посетителя на территории Водного комплекса.
              </li>
              <li>
              В случае порчи или утери браслета, Посетитель обязуется возместить ущерб в размере 200 000 сум без учета НДС
              </li>
              <li>
              В случае порчи имущества Водного Комплекса, Посетитель обязуется компенсировать причинённый ущерб и возместить сумму согласно прейскуранту
              </li>
              <li>
              Посетитель обязуется использовать сменную, летнюю обувь на резиновых подошвах (тапочки, сланцы, шлепанцы и т.д.).
              </li>
              <li>
              В целях гигиены, в Водном комплексе "МОРЕ" запрещается посещение и купание в нижнем (хлопчатобумажном) белье, в верхней одежде и в одежде, не приспособленной для купания в общественном бассейне.
              </li>
              <li>
              Разрешено курить только в специально отведенных местах Водного комплекса.
              </li>
              <li>
              Запрещается употреблять пищу и напитки в раздевалках, в бассейне и на бортике бассейна.
              </li>
              <li>
              Запрещается перед посещением бассейна бриться, стричь волосы, ногти, оставлять после себя использованные средства гигиены, использованные подгузники и скоблить кожный покров (пятки), производить депиляцию в душевых кабинках Водного Комплекса "МОРЕ".
              </li>
              <li>
              Запрещается нырять и плавать с распущенными волосами.
              </li>
              <li>
              Запрещено прыгать в бассейн на головку/бомбочкой и т.д., играть в опасные игры и толкать купающихся.
              </li>
              <li>
              Запрещено прыгать в воду слишком близко к другим плавающим посетителям.
              </li>
              <li>
              Запрещено сидеть на бортике, свисать на бортике в зоне джакузи, смотрящей в сторону центрального бассейна.
              </li>
              <li>
              По требованию службы безопасности Водного Комплекса, Посетитель обязан предъявить содержание личных вещей для визуального осмотра.
              </li>
              <li>
              Запрещается проносить на территорию Водного комплекса:
              </li>
              <li>
              Любых животных, птиц, рептилий и т.д.
              </li>
              <li>
              Продукты питания и напитки
              </li>
              <li>
              Любые изделия из стекла, огнестрельное, газовое, пневматическое и холодное оружие, колюще-режущие предметы, а также взрывчатые, легковоспламеняющиеся, токсичные вещества и вещества с сильным запахом
              </li>
              <li>
              Наркотические, психотропные и любые одурманивающие средства
              </li>
              <li>
              Любые иные вещества и предметы, которые создают угрозу безопасности Посетителей Водного комплекса "МОРЕ"
              </li>
              <li>
              Проводить торговую, рекламную или иную коммерческую деятельность, а также видео- и фотосъемку без соответствующего разрешения администрации Водного комплекса.
              </li>
              <li>
              Нарушение Посетителем настоящих правил, а также отказ от их выполнения, является основанием для отказа в оказании услуг и нахождения на территории Водного комплекса без возврата денежных средств в размере стоимости абонемента.
              </li>
              <li>
              Нарушение правил или отказ от их выполнения является основанием для отказа в оказании услуг и нахождения на территории комплекса без возврата денежных средств.
              </li>
              <li>
              Посетитель несет ответственность за любые последствия, произошедшие на территории Водного комплекса в результате незнания или несоблюдения настоящих Правил, а также за наличие у него любых противопоказаний для использования услуг комплекса и нарушения правил технической безопасности.
              </li>
              <li>
              Администрация водного комплекса не несет ответственности за сохранность/утерю личных вещей Посетителя.
              </li>
              <li>
              Посетитель дает согласие на соблюдение Правил и несет полную ответственность за их нарушение в соответствии с настоящими Правилами и действующим законодательством Республики Узбекистан.
              </li>
              <li>
              Администрация Водного комплекса не несет ответственности за вред/ущерб жизни, здоровью, имуществу Посетителей, который был получен в результате умышленных или неосторожных действий, несчастных случаев, вследствие невыполнения или ненадлежащего выполнения настоящих Правил, несоблюдения им правил техники безопасности, а также действий третьих лиц.
              </li>
            </ul>
  `,
  uz: `
  <h3>ODOB QOIDALARI</h3>

<ul>
  <li>
    Telman The HAMMOM majmuasi hududida quyidagi dress-kod amal qiladi: restoran
    zonasida butunlay yalang'och bo'lib yurish mumkin emas; kassa maydoniga
    yalang'och bo'lib chiqish taqiqlanadi; yuvinish zonasida to'liq kiyimsiz
    yurishga ruxsat beriladi; majmuaga ichki kiyimda tashrif buyurish qat'iyan
    man etiladi; bug' xonasiga metall va plastik bezaklari, shuningdek, issiq
    bosilgan nakleykalari bo'lmagan cho'milish kostyumlarida kirishga ruxsat
    etiladi; hammom majmuasini xalat/sochiq/choyshab/keltda tark etish qat'iyan
    man etiladi; restoran zaliga, VIP xonalarga va yuvinish bo'limiga ustki
    kiyim, ko'cha poyabzali yoki paypoqlarda kirish qat'iyan man etiladi; 
  </li>

  <li>
    Telman THE HAMMOM majmuasi sizga lutfan bitta hammom sochiq va choyshab
    taqdim etadi. Shaxsiy almashtiriladigan poyabzal, hammom shapkasi, xalat
    yoki keltni o'zingiz bilan olib kelishga ruxsat beriladi. Shuningdek, xalat
    va keltni hammom majmuasi ijaraga berishi mumkin. Hammom shapkasi va bir
    martalik shippaklarni kassa maydonida sotib olishingiz mumkin. 
  </li>

  <li>
    Faqat Telman The HAMMOM majmuasi hududida sotib olingan cho'p dastalar bilan
    bug'lanishga ruxsat beriladi;
  </li>

  <li>
    Shaxsiy asal, minerallar, tuzlar va boshqa hammom va spa kosmetikasini olib
    o'tishga va ishlatishga yo'l qo'yilmaydi; 
  </li>

  <li>
    Hammom majmuasiga o'z xodimlari va jihozlari bilan kelish taqiqlanadi;
  </li>

  <li>
    O'zi bilan ovqat, alkogolli ichimliklar, kam alkogolli va alkogolsiz
    ichimliklar olib kelish taqiqlanadi, bolalar ovqatlari bundan mustasno.
  </li>

  <li>Hammom majmuasi hududida har qanday intim yaqinlik taqiqlanadi;</li>

  <li>
    Erkaklar bo'limiga ayollar, ayollar bo'limiga erkaklar kirishiga yo'l
    qo'yilmaydi (hayotga tahdid tug'ilishi bilan bog'liq fors-major holatlari
    bundan mustasno). 
  </li>

  <li>
    Hammom majmuasi spirtli ichimliklar yoki giyohvand moddalardan mast bo'lish,
    shuningdek tajovuzkor xatti-harakatlar holatlarida majmuaga kiritmaslik yoki
    xizmatlarni taqdim etishdan bosh tortish huquqiga ega;
  </li>

  <li>Hayvonlar bilan hammom majmuasiga tashrif buyurish taqiqlanadi;</li>

  <li>Xodimlarni haqorat qilish va nojo'ya harakatlar qilish taqiqlanadi;</li>

  <li>
    Mulkka zarar yetkazish taqiqlanadi. Mulkka zarar yetkazilgan taqdirda,
    mehmonga preyskurantga muvofiq hisob beriladi;
  </li>

  <li>
    Hammomda to'langan vaqtdan 15 daqiqadan ortiq vaqt davomida qolinganda,
    hisobga avtomatik ravishda qo'shimcha bir soat qo'shiladi;
  </li>

  <li>
    Chekish xonasi va balkondan boshqa joylarda sigaret/saqich
    tamaki/nosvoy/veyp va har qanday elektron sigaretlar chekish taqiqlanadi;
  </li>

  <li>
    Yugurish, baqirish va his-tuyg'ularni baland ovozda ifoda etish taqiqlanadi;
  </li>

  <li>Jomlar va basseynlarga sho'ng'ish taqiqlanadi;</li>

  <li>Oldin dushda yuvinmasdan bug' xonasiga kirish taqiqlanadi;</li>

  <li>Bug' xonasida gaplashish taqiqlanadi;</li>

  <li>
    Mehmonlarimizning dam olish hududida videokuzatuv mavjud emas, shu sababli
    qimmatbaho narsalarni registraturada seyfda qoldirishni tavsiya qilamiz, aks
    holda ma'muriyat yo'qotilgan narsalar uchun hech qanday javobgarlikni
    bo'yniga olmaydi. 
  </li>
</ul>

<p>
  <em>
    * Yuqorida sanab o'tilgan talablar buzilgan holda, biz xizmatlarni taqdim
    etishni to'xtatishga, shuningdek mehmonni hammomimiz xizmatlaridan, shu
    jumladan allaqachon to'langan bron qilingan xizmatlardan ham, uning narxini
    qoplamasdan foydalanish huquqidan mahrum qilishga haqlimiz.
  </em>
</p>

<h3>O'Z SOG'LIG'INGIZ UCHUN XAVFSIZLIK QOIDALARI</h3>

<ul>
  <li>
    1 yoshgacha bo'lgan bolalar. Termoregulyatsiya tizimi hali to'liq
    rivojlanmagan, bu haddan tashqari qizib ketishga va salbiy oqibatlarga olib
    kelishi mumkin.
  </li>

  <li>
    Kasalligi o'tkir shaklda kechayotgan odamlar, rivojlanish avjidagi har
    qanday yallig'lanish, shu jumladan shamollash. Hammom muolajalarining
    ta'siri salbiy bo'lishi, kasallikning kechishi kuchayishi mumkin.
  </li>

  <li>
    Homilador ayollar. Tushish xavfini oldini olish uchun har qanday holatda ham
    bug'lanish mumkin emas.
  </li>

  <li>
    Spirtli ichimliklar yoki giyohvand moddalardan mast bo'lganda. Yurakka bosim
    juda katta bo'ladi.
  </li>

  <li>
    Boshidan insult o'tkazgan odamlar. Undan keyin bir yil davomida hammomga
    borish mumkin emas. Tomirlar kengayishi hayot uchun xavfli.
  </li>

  <li>Infarkt. 6-8 hafta davomida bug'lanish mumkin emas.</li>

  <li>Onkologiya bilan og'rigan odamlar. Uning kuchayishi xavfi mavjud.</li>

  <li>
    Angidroz – terlash yo'qligi. Organizm haddan tashqari qizib ketadi, issiqlik
    urishi xavfi mavjud.
  </li>

  <li>
    Diabetiklar. Bug' xonasida insulin va insulinni bog'laydigan moddalar ter
    bilan birga chiqadi. Shuning uchun qondagi shakar miqdori keskin pasayishi
    yoki ortishi mumkin.
  </li>

  <li>
    Yurak kasali – aritmiya, ishemiya, aorta stenozi, gipotenziya, gipertenziya
    bilan og'rigan odamlar. Bosimning har qanday o'zgarishi sog'liqqa salbiy
    ta'sir qiladi.
  </li>

  <li>Yuqori haroratda, bosh og'rig'ida.</li>

  <li>
    Kuchli bitmaydigan yaralar, shilliq pardalarning shikastlanishi, kuyishdan
    keyingi shikastlanishlar yuzasi katta bo'lganida, xolinergik eshak emi,
    tizimli qizil volchanka kasalliklarida. Kasalliklar kechishi kuchayadi.
  </li>

  <li>Tomir tortishishidan aziyat chekadigan odamlar.</li>

  <li>Silikon va metall implantlar mavjudligi.</li>

  <li>
    Bosh miya shikastlanishi. Undan keyin bir yil davomida hammomga borish
    mumkin emas.
  </li>

  <li>Yurak stimulyatori mavjud bo'lsa.</li>

  <li>Hayz davridagi ayollarga. Qon ketish xavfi mavjud.</li>
</ul>

<h4>
  <strong>
    YOSH CHEKLOVLARI VA YENGIL KASALLIKLAR BILAN BOG'LIQ TAVSIYALAR:
  </strong>
</h4>

<ul>
  <li>
    1 – 2 yoshdagi bolalar. 55-60 daraja harorat va 20% gacha namlikda pastki
    javonda bir marta 2-3 daqiqadan oshmagan vaqt davomida saunda bo'lish
    mumkin.
  </li>

  <li>
    2 yoshdan 6 yoshgacha bo'lgan sog'lom bolalar. Saunada o'tkaziladigan vaqt
    55-60 daraja haroratda va 20% gacha namlikda 5 daqiqadan oshmasligi kerak.
  </li>

  <li>
    6 yoshdan oshgan bolalar, 16 yoshgacha bo'lgan o'smirlar. Kattalar
    hamrohligida va 70-80 daraja haroratda 15 daqiqadan ko'p bo'lmagan vaqt
    davomida.
  </li>

  <li>
    Surunkali yurak yetishmovchiligida. Havo namligi 20% dan ortiq bo'lmagan
    55-60 daraja haroratda 10 daqiqa davomida ruxsat etiladi.
  </li>

  <li>
    Termoregulyatsiya va terlashga ta'sir qiluvchi dorilar qabul qilganda.
  </li>

  <li>
    2-darajadagi va undan yuqori darajali semirib ketishda. Bunday odamlarda
    termoregulyatsiya buzilgan.
  </li>

  <li>
    Emizikli ayollar. Organizmdan teri bilan chiqadigan toksinlar sutga tushishi
    mumkin.
  </li>

  <li>Hammomdan 2 soat oldin to'yib ovqatlangan odamlar.</li>

  <li>
    60 yoshdan oshgan keksa odamlar. Sovuq basseynda suv quyish va cho'milishni
    juda ehtiyotkorlik bilan qilish kerak. Bu yurakka kuchli bosim.
  </li>
</ul>

<p>
  Yaqinda jismoniy mashqlar bilan intensiv shug'ullangan odamlarga hammomga
  ehtiyotkorlik bilan borish tavsiya etiladi. Bu yurak-qon tomir tizimining
  buzilishiga olib kelishi mumkin.
</p>

<p>
  Hatto sog'liq ko'rsatkichlariga ko'ra juda kuchli odamlarga ham bug' xonasida
  bir marta kirishda 30 daqiqadan ko'proq vaqt qolish tavsiya etilmaydi. Chunki
  tana qizib ketadi. Va chiqqandan so'ng darhol muzdek suv quyish mumkin emas.
  Organizm xona haroratida sovishini kutish kerak.
</p>

<p>
  Borishdan oldin shifokor ko'rigidan o'tishingizni tavsiya qilamiz. Sizning
  sog'lig'ingizda bunday muolajalar mumkinligiga ishonch hosil qiling. Va mazza
  qilib bug'laning!
</p>

<h3>ATROFDAGILARNING SOG'LIG'I XAVFSIZLIGI QOIDALARI</h3>

<h4>
  QUYIDAGI HOLLARDA BUG' VA CHO'MILISH BO'LIMIGA TASHRIF BUYURISH QAT'IYAN MAN
  ETILADI: 
</h4>

<ul>
  <li>
    Har qanday teri kasalliklari (qipiqli temiratki, ekzema, atopik dermatit,
    temiratki, piodermiy, akne, demodekoz va boshqalar);
  </li>

  <li>
    Oyoq va tirnoqlarning yuqumli teri kasalliklari (zamburug'li infeksiyalar,
    tirnoqlar onixolizisi va boshqalar)
  </li>

  <li>
    Jinsiy yo'l bilan yuqadigan kasalliklar (sifilis, gonoreya, xlamidioz,
    trixomoniaz va boshqalar);
  </li>

  <li>Ochiq yaralar, yaralar, hayz yoki boshqa qon ketishi;</li>

  <li>Diareya; </li>

  <li>Gepatit A</li>

  <li>Koronovirus mavjudligi</li>

  <li>
    Yuqumli o'pka kasalliklari (bronxit, pnevmoniya, sil, gripp va boshqalar) 
  </li>
</ul>

<p>
  Boshqa har qanday virusli infeksiyalar (gerpes, suvchechak, qizamiq, ko'k
  yo'tal, ornitozi va boshqalar); bakterial infeksiyalar (dizenteriya,
  salmonellez, vabo, o'lat va boshqalar); zamburug'li infeksiyalar (kandidoz,
  temiratki va boshqalar)
</p>
`,
  en: `
  <h3>RULES OF BEHAVIOUR</h3>

<ul>
  <li>
    The following dress code applies in the territory of The Telman Bath
    Complex: it is forbidden to be completely naked in the restaurant area; it
    is forbidden to go naked to the cash zone; in the washing area it is allowed
    to be completely unclothed; it is strictly forbidden to visit the complex in
    underwear; it is allowed to visit the steam room in swimming costumes
    without metal and plastic decorations, as well as without thermo-printed
    stickers; it is strictly forbidden to leave the bathing complex in
    bathrobes/towel/sheets/kettle; it is strictly forbidden to enter the
    restaurant hall, VIP rooms and washing area in outerwear, street shoes or
    socks;
  </li>

  <li>
    THE BANI Telmana Bath Complex will kindly provide you with one bath towel
    and a sheet. It is allowed to bring your own personal change of shoes, bath
    cap, bathrobe or kelt. Also, the bath complex can provide you with a
    bathrobe and celt for rent. You can buy a bath cap and disposable slippers
    at the cashier's area.
  </li>

  <li>
    It is allowed to use brooms purchased only on the territory of The Banya
    Telmana bath complex;
  </li>

  <li>
    It is not allowed to bring and use personal honey, minerals, salts and other
    bath and spa cosmetics;
  </li>

  <li>
    It is forbidden to come to the bathing complex with your own staff and
    equipment;
  </li>

  <li>
    It is forbidden to bring your own food, alcoholic, low-alcoholic and
    non-alcoholic drinks, with the exception of baby food.
  </li>

  <li>
    It is forbidden to have any intimate intimacy on the territory of the
    bathing complex;
  </li>

  <li>
    It is not allowed to visit the men's category by women and the women's
    category by men (except for force majeure circumstances related to the
    threat to life).
  </li>

  <li>
    The bathing complex has the right to refuse to admit or refuse to provide
    services in cases of alcoholic or drug intoxication, as well as aggressive
    behaviour;
  </li>

  <li>It is forbidden to visit the bathing complex with animals;</li>
  <li>It is forbidden to insult the staff and improper behaviour;</li>

  <li>
    Property damage is prohibited. In case of property damage, the guest will be
    billed according to the price list;
  </li>

  <li>
    When staying in the bathhouse for more than 15 minutes over the paid time,
    an additional hour of visit is automatically added to the bill;
  </li>

  <li>
    It is forbidden to smoke cigarettes/chewing tobacco/nasvay/vapes and any
    electronic cigarettes in the premises, except for the smoking room and
    balcony;
  </li>

  <li>Running, shouting and expressing loud emotions is prohibited;</li>

  <li>It is forbidden to dive into the baths and swimming pools;</li>

  <li>
    It is forbidden to visit the steam room without first rinsing in the shower;
  </li>

  <li>It is forbidden to talk in the steam room;</li>

  <li>
    There is no video surveillance on the recreation area of our guests, in this
    regard we recommend that you leave valuables in the safe at the reception,
    otherwise the administration does not bear any responsibility for the loss.
  </li>
</ul>

<p>
  <em>
    * In case of violation of the above requirements, we have the right to
    terminate the services and to deprive the guest of the right to continue
    using the services of our bathhouse, including the already paid reservation,
    without reimbursement of its cost.
  </em>
</p>

<h3>HEALTH SAFETY RULES</h3>

<ul>
  <li>
    Children under 1 year old. The thermoregulation system is not yet fully
    developed, which may lead to overheating and unfavourable consequences.
  </li>

  <li>
    People whose disease runs in an acute form, any inflammation, including the
    common cold at the peak of development. The effect of bathing procedures can
    be negative, the course of the disease can be aggravated.
  </li>

  <li>
    Pregnant women. In no case should not be steamed to avoid the risk of
    miscarriage.
  </li>

  <li>
    In a state of intoxication of alcohol or drugs. The load on the heart will
    be colossal.
  </li>

  <li>
    People who have suffered a stroke. For a year after it, you can not visit
    the bathhouse. Dilation of blood vessels is dangerous for life.
  </li>

  <li>Infarction. For 6-8 weeks you can not steam.</li>

  <li>People with oncology. There is a risk of its aggravation.</li>

  <li>
    Anhidrosis - lack of sweating. The body will overheat, there is a risk of
    getting a heat stroke..
  </li>

  <li>
    Diabetics. In the steam room together with sweat withdraws insulin and
    substances that bind insulin. Therefore, blood sugar can sharply decrease or
    increase.
  </li>

  <li>
    Cardiac - arrhythmia, ischaemia, aortic stenosis, hypotension, hypertension.
    Any change in blood pressure will have a detrimental effect on well-being.
  </li>

  <li>In fever, headache.</li>

  <li>
    In severe non-healing wounds, injuries of mucous membranes, large lesion
    surface after burns, cholinergic urticaria, systemic lupus erythematosus.
    The course of the diseases will worsen.
  </li>

  <li>People suffering from seizures.</li>

  <li>The presence of implants - made of silicone and metal.</li>

  <li>Cranial trauma. After it, you can not visit the sauna for a year.</li>

  <li>In the presence of a pacemaker.</li>

  <li>Women during menstruation. There is a risk of bleeding.</li>
</ul>

<h4>
  <strong>
    RECOMMENDATIONS RELATED TO AGE RESTRICTIONS AND MILD ILLNESSES:
  </strong>
</h4>

<ul>
  <li>
    Children, ages 1 - 2 years. It is allowed to stay in a sauna with a
    temperature of 55-60 degrees and humidity up to 20% not more than 2-3
    minutes once on the bottom shelf.
  </li>

  <li>
    Healthy children aged 2 to 6 years. Allowed at a temperature of 55-60
    degrees and humidity up to 20% not more than 5 minutes.
  </li>

  <li>
    Children over 6 years old, teenagers up to 16 years old. Accompanied by
    adults and not more than 15 minutes at a temperature of 70-80 degrees.
  </li>

  <li>
    For chronic heart failure. Allowed at a temperature of 55-60 degrees with
    humidity not more than 20% for 10 minutes.
  </li>

  <li>When taking medications that affect thermoregulation and sweating.</li>

  <li>
    Obesity of the 2nd degree and above. Thermoregulation in such people is
    impaired.
  </li>

  <li>
    Women who are breastfeeding. Toxins can get into the milk, which are removed
    from the body with sweat.
  </li>

  <li>
    People who have taken a large meal less than 2 hours before taking a bath.
  </li>

  <li>
    Elderly people over 60 years of age. Special care should be taken when
    dousing and bathing in a cold pool.
  </li>
</ul>

<p>
  It is also recommended to visit the sauna with caution for people who have
  recently been intensively engaged in physical exercise. This can lead to a
  violation of the cardiovascular system.
</p>

<p>
  Even very strong in terms of health people in the steam room is not
  recommended to be more than 30 minutes in one run. Because the body overheats.
  And immediately after leaving you can not pour ice water. It is necessary to
  let the body cool down at room temperature.
</p>

<p>
  It is recommended to be examined by a doctor before visiting. Make sure that
  your health allows such procedures. And steam to your health!
</p>

<h3>RULES FOR THE HEALTH SAFETY OF OTHERS</h3>

<h4>
  IT IS STRICTLY FORBIDDEN TO VISIT THE STEAM AND SOAP ROOM IN THE FOLLOWING
  CASES:
</h4>

<ul>
  <li>
    Any skin diseases (psoriasis, eczema, atopic dermatitis, lichen planus,
    pyoderma, acne, demodecosis, etc.);
  </li>

  <li>
    Infectious diseases of the skin of the feet and nails (fungal infections,
    onycholysis of the nails, etc.).
  </li>

  <li>
    Venereal diseases (syphilis, gonorrhoea, chlamydia, trichomoniasis, and
    others);
  </li>

  <li>Open sores, wounds, menstruation or other bleeding;</li>

  <li>Diarrhoea;</li>

  <li>Hepatitis A</li>

  <li>Presence of coronaviruses</li>

  <li>
    Infectious lung diseases (bronchitis, pneumonia, tuberculosis, influenza,
    etc.)
  </li>
</ul>

<p>
  Any other viral infections (herpes, varicella, measles, whooping cough,
  ornithosis, etc.); bacterial infections (dysentery, salmonellosis, cholera,
  plague, etc.); fungal infections (candidiasis, lichen planus, etc.)
</p>
`,
};
