export const m = {
  ru: {
    firstName: "Имя",
    lastName: "Фамилия",
    phone: "Номер телефона",
    gender: "Пол",
    gender1: "Мужской",
    gender0: "Женский",
    birthdate: "Дата рождения",
    policyConfirm: "Даю согласие на соблюдение выше указанных правил и несу полную ответственность за их нарушение и Я выражаю согласие на обработку персональных данных",
    confirm: "Подтвердить",
    sendCode: "Отправить код подтверждения",
    codeSent: "Код отправлен, пожалуйста введите его",
    code: "Код подтверждения",
    incorrectFields: "Пожалуйста заполните все поля корректно!",
    regSuccess: "Поздравляем, вы прошли регистрацию!",
    smthngWentWrong: "Что-то пошло не так, пожалуйста попробуйте позже",
    incorrectOTP: "Неправильный код подтверждения",
  },
  uz: {
    firstName: "Ism",
    lastName: "Familiya",
    phone: "Telefon raqam",
    gender: "Jinsi",
    gender1: "Erkak",
    gender0: "Ayol",
    birthdate: "Tug'ilgan sanasi",
    policyConfirm: "Barcha shartlarni qabul qilaman",
    confirm: "Tasdiqlash",
    sendCode: "Tasdiqlash kodini jo'natish",
    codeSent: "Tasdiqlash kodi jo'natildi, iltimos uni kiriting",
    code: "Tasdiqlash kodi",
    incorrectFields: "Iltimos, barcha maydonlarni to'g'ri to'ldiring!",
    regSuccess: "Tabriklaymiz, ro'yxatdan o'tdingiz!",
    smthngWentWrong: "Xatolik yuz berdi, iltimos birozdan so'ng urunib ko'ring",
    incorrectOTP: "Tasdiqlash kodi noto'g'ri",
  },
  en: {
    firstName: "Firstname",
    lastName: "Lastname",
    phone: "Phone",
    gender: "Gender",
    gender1: "Male",
    gender0: "Female",
    birthdate: "Birthdate",
    policyConfirm: "I accept all conditions",
    confirm: "Confirm",
    sendCode: "Send confirm code",
    codeSent: "Code sent, please enter it",
    code: "Confirmation code",
    incorrectFields: "Please fill out all fields correctly!",
    regSuccess: "Congratulations, you have registered!",
    smthngWentWrong: "Something went wrong, please try again later",
    incorrectOTP: "Incorrect confirmation code",
  },
};
