import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
// @ts-ignore:
import InputMask from "react-input-mask";

import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ru } from "date-fns/locale/ru";
import { m } from "./messages";
import { p } from "./policy";

import "./App.css";
import { useEffect, useRef, useState } from "react";
import { IsValidPhoneFormat, clearPhoneNumber } from "./helper";
import moment from "moment";

import { useLocalStorage } from "./hooks/useLocalStorage";

function App() {
  registerLocale("ru", ru);

  const [lang, setLang] = useLocalStorage("lang", "ru");

  const MySwal = withReactContent(Swal);

  // STATES
  const [name, setName] = useState("");
  const [lastname, setLastname] = useState("");
  const [phone, setPhone] = useState("+998");
  const [gender, setGender] = useState(null);
  const [birthdate, setBirthdate] = useState("");
  const [publicCheck, setPublicCheck] = useState(false);
  const [code, setCode] = useState("");
  const [isCodeSent, setIsCodeSent] = useState(false);

  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [userTouch, setUserTouch] = useState(false);
  const timeoutRef = useRef(null);

  function getMonth(date) {
    const selectedDate = new Date(date);
    return selectedDate.getMonth();
  }

  const range = (start, end) => {
    const length = end - start;
    return Array.from({ length }, (_, i) => start + i);
  };
  const years = range(1940, new Date().getFullYear());
  const months = [
    "Январь",
    "Февраль",
    "Март",
    "Апрель",
    "Май",
    "Июнь",
    "Июль",
    "Август",
    "Сентябрь",
    "Октябрь",
    "Ноябрь",
    "Декабрь",
  ];

  useEffect(() => {
    const resetTimeout = () => {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        window.location.reload();
      }, 3 * 60 * 1000);
      setUserTouch(false);
    };

    const onMouseMove = () => {
      if (isFirstLoad) {
        setIsFirstLoad(false);
        setUserTouch(true);
      }
      userTouch && resetTimeout();
    };

    const onTouchStart = () => {
      if (isFirstLoad) {
        setIsFirstLoad(false);
        setUserTouch(true);
      }
      userTouch && resetTimeout();
    };

    userTouch && resetTimeout();

    document.addEventListener("click", onMouseMove);
    document.addEventListener("touchstart", onTouchStart);

    return () => {
      document.removeEventListener("click", onMouseMove);
      document.removeEventListener("touchstart", onTouchStart);
      clearTimeout(timeoutRef.current);
    };
  }, [isFirstLoad]);

  const sendCode = async () => {
    let userPhone = "";
    if (phone.length > 4) {
      userPhone = clearPhoneNumber(phone);
    }

    if (!IsValidPhoneFormat(userPhone)) {
      MySwal.fire({
        position: "center",
        icon: "error",
        title: m[lang].incorrectFields,
        showConfirmButton: false,
        timer: 1500,
      });

      return;
    }

    var bodyFormData = new FormData();
    bodyFormData.append("phone", userPhone);

    try {
      let result = await axios.post(
        "https://more.botagent.uz/v1/api/send-code",
        bodyFormData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      MySwal.fire({
        position: "center",
        icon: "success",
        title: m[lang].codeSent,
        showConfirmButton: false,
        timer: 1500,
      });

      setIsCodeSent(true);
    } catch (error) {
      MySwal.fire({
        position: "center",
        icon: "error",
        title: m[lang].smthngWentWrong,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };
  const confirm = async () => {
    let userPhone = "";
    if (phone.length > 4) {
      userPhone = clearPhoneNumber(phone);
    }

    if (
      !name ||
      !lastname ||
      !IsValidPhoneFormat(userPhone) ||
      gender == null ||
      !birthdate ||
      !publicCheck ||
      !code
    ) {
      MySwal.fire({
        position: "center",
        icon: "error",
        title: m[lang].incorrectFields,
        showConfirmButton: false,
        timer: 1500,
      });

      return;
    }

    // CHECK CODE

    var codeCheckbodyFormData = new FormData();
    codeCheckbodyFormData.append("phone", userPhone);
    codeCheckbodyFormData.append("code", code);

    try {
      let result = await axios.post(
        "https://more.botagent.uz/v1/api/check-code",
        codeCheckbodyFormData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      if ((result.data || {}).stateCode != 200) {
        MySwal.fire({
          position: "center",
          icon: "error",
          title: m[lang].incorrectOTP,
          showConfirmButton: false,
          timer: 1500,
        });
        return;
      }
    } catch (error) {
      MySwal.fire({
        position: "center",
        icon: "error",
        title: m[lang].smthngWentWrong,
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }

    // REGISTRATION
    var bodyFormData = new FormData();
    bodyFormData.append("system", "InfoKiosk");
    bodyFormData.append("first_name", name);
    bodyFormData.append("last_name", lastname);
    bodyFormData.append("name", lastname);
    bodyFormData.append("gender", gender);
    bodyFormData.append("birthday", moment(birthdate).format("YYYY-MM-DD"));
    bodyFormData.append("phone", userPhone);

    try {
      let result = await axios.post(
        "https://more.botagent.uz/v1/api/register",
        bodyFormData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      MySwal.fire({
        position: "center",
        icon: "success",
        title: m[lang].regSuccess,
        showConfirmButton: false,
        timer: 1500,
      });

      setName("");
      setLastname("");
      setPhone("+998");
      setGender(null);
      setBirthdate("");
      setPublicCheck(false);
      setIsCodeSent(false);
    } catch (error) {
      MySwal.fire({
        position: "center",
        icon: "error",
        title: m[lang].smthngWentWrong,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  return (
    <div className="App">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 text-center col-wrapper">
            <div>
              {/* <div
                className="es-lang-wrapper position-absolute"
                style={{ top: "10px" }}
              >
                <img
                  src="flag-uz.png"
                  alt=""
                  width={40}
                  className="me-3"
                  onClick={(e) => {
                    setLang("uz");
                  }}
                />
                <img
                  src="flag-ru.png"
                  alt=""
                  width={40}
                  className="me-3"
                  onClick={(e) => {
                    setLang("ru");
                  }}
                />
                <img
                  src="flag-en.png"
                  alt=""
                  width={40}
                  onClick={(e) => {
                    setLang("en");
                  }}
                />
              </div> */}
              <img
                className="my-5"
                src="logo.png"
                alt=""
                style={{ width: "40%" }}
              />
            </div>

            <div className="w-100 text-start px-0 px-md-5">
              <div className="mb-3">
                <label htmlFor="nameInput" className="form-label">
                  {m[lang].firstName}:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="nameInput"
                  autoComplete="off"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="lastnameInput" className="form-label">
                  {m[lang].lastName}:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="lastnameInput"
                  autoComplete="off"
                  onChange={(e) => setLastname(e.target.value)}
                  value={lastname}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">{m[lang].phone}:</label>
                <InputMask
                  className="form-control"
                  mask="+999 (99) 999-99-99"
                  // @ts-ignore:
                  onChange={(e) => setPhone(e.currentTarget.value)}
                  value={phone}
                  autoComplete="off"
                />
              </div>
              <div className="mb-3">
                <label className="form-label">{m[lang].gender}:</label>

                <div className="d-flex">
                  <button
                    className={`btn w-100 me-4 ${
                      gender == 1 ? "btn-primary" : "btn-outline-primary"
                    }`}
                    onClick={() => setGender(1)}
                  >
                    {m[lang].gender1}
                  </button>
                  <button
                    className={`btn w-100 ${
                      gender == 0 ? "btn-danger" : "btn-outline-danger"
                    }`}
                    onClick={() => setGender(0)}
                  >
                    {m[lang].gender0}
                  </button>
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="dateInput" className="form-label">
                  {m[lang].birthdate}:
                </label>
                {/* <input
                  type="date"
                  className="form-control"
                  id="dateInput"
                  autoComplete="off"
                  onChange={(e) => setBirthdate(e.target.value)}
                  value={birthdate}
                  lang="ru-RU"
                /> */}
                <br />
                <DatePicker
                  locale="ru"
                  renderCustomHeader={({
                    date,
                    changeYear,
                    changeMonth,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                  }) => (
                    <div
                      style={{
                        margin: 10,
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <button
                        onClick={decreaseMonth}
                        disabled={prevMonthButtonDisabled}
                      >
                        {"<"}
                      </button>
                      <select
                        value={date.getFullYear()}
                        onChange={({ target: { value } }) => changeYear(value)}
                      >
                        {years.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>

                      <select
                        value={months[getMonth(date)]}
                        onChange={({ target: { value } }) =>
                          changeMonth(months.indexOf(value))
                        }
                      >
                        {months.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>

                      <button
                        onClick={increaseMonth}
                        disabled={nextMonthButtonDisabled}
                      >
                        {">"}
                      </button>
                    </div>
                  )}
                  selected={birthdate}
                  onChange={(date) => setBirthdate(date)}
                />
              </div>

              {/* <div className="mb-3 form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="publicCheck"
                  onChange={(e) => {
                    setPublicCheck(e.target.checked);
                  }}
                  checked={publicCheck}
                />
                <label className="form-check-label" htmlFor="publicCheck">
                  {m[lang].policyConfirm}
                </label>
              </div>

              {!isCodeSent && (
                <button
                  className="btn btn-lg btn-primary w-100"
                  onClick={sendCode}
                >
                  {m[lang].sendCode}
                </button>
              )} */}
              {isCodeSent && (
                <>
                  <div className="mb-3">
                    <label htmlFor="codeInput" className="form-label">
                      {m[lang].code}:
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="codeInput"
                      autoComplete="off"
                      onChange={(e) => setCode(e.target.value)}
                      value={code}
                    />
                  </div>
                  <button
                    className="btn btn-lg btn-primary w-100"
                    onClick={confirm}
                  >
                    {m[lang].confirm}
                  </button>
                </>
              )}
            </div>
          </div>
          <div className="col-md-6 px-5 py-5 col-wrapper col-wrapper-scroll">
            <div dangerouslySetInnerHTML={{ __html: p[lang] }}></div>
            <div className="mb-3 form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="publicCheck"
                onChange={(e) => {
                  setPublicCheck(e.target.checked);
                }}
                checked={publicCheck}
              />
              <label className="form-check-label" htmlFor="publicCheck">
                {m[lang].policyConfirm}
              </label>
            </div>

            {!isCodeSent && (
              <button
                className="btn btn-lg btn-primary w-100"
                onClick={sendCode}
              >
                {m[lang].sendCode}
              </button>
            )}
          </div>
        </div>
      </div>
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}
    </div>
  );
}

export default App;
